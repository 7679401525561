<template>
  <div class="dashboard">
    <navbar class="nav-bar" />
    <mobileNavbar class="mobile-navbar" />
    <router-view />
  </div>
</template>

<script>
import navbar from "@/views/dashboard/navbar";
import mobileNavbar from "@/views/dashboard/mobile-navbar";

export default {
  name: "",
  components: { navbar, mobileNavbar },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="stylus" scoped>

.dashboard {
  width 100%
  flex-grow 1
  display flex
  flex-direction column
}

@media only screen and (min-width: 600px) {
  .mobile-navbar {
    display none
  }
}

//mobile


@media only screen and (max-width: 600px) {
  .nav-bar {
    display none
  }
}

</style>