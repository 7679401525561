<template>
  <div class="mobile-navbar">
    <div class="fixed">
      <div class="fixed-content">
        <div class="header-title">
          <el-image class="" :src="require('@/assets/hnlogo.svg')"></el-image>
        </div>
        <div class="nav-menu">
          <el-button class="menu-button" icon="el-icon-menu" @click="drawer = true"></el-button>
        </div>
        <div class="menu-container">
          <el-menu class="menu" :default-active="activeIndex" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1">首页</el-menu-item>
            <el-menu-item index="2">
              <el-dropdown class="dropdown-class" @command="handleCommand" placement="bottom">
                <span class="user">星服务</span>
                <el-dropdown-menu class="dropdown-class" slot="dropdown">
                   
                  <!-- <el-dropdown-item command="proofWeb">星存证</el-dropdown-item>
                  <el-dropdown-item command="search">星检索</el-dropdown-item>
                  <el-dropdown-item command="elite">星俊才</el-dropdown-item>
                  <el-dropdown-item command="assistant">星助理</el-dropdown-item>
                  <el-dropdown-item command="delegate">星代理</el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </el-menu-item>
            <el-menu-item index="6">业务说明</el-menu-item>
            <el-menu-item index="3">通知公告</el-menu-item>
            <el-menu-item index="4">新闻中心</el-menu-item>
            <el-menu-item index="5">政策法规</el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>
    
    <div class="fixed-bottom">
      <div class="solid-banner">
        <el-image class="solid-image" fit="fill" :src="require('@/assets/' + navImage + '.png')"></el-image>
      </div> 
    </div>

    <el-drawer class="nav-drawer" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
      <el-menu class="menu" :default-active="activeIndex" mode="vertical" :collapse="collapse" @select="handleSelect">
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">
          <el-dropdown class="dropdown-class" @command="handleCommand">
            <span class="user">星服务</span>
            <el-dropdown-menu class="dropdown-class" slot="dropdown">
                <el-dropdown-item  v-for="menu in menus" :key="menu.caption" :command="menu.command">{{menu.caption}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
        <el-menu-item index="6">业务说明</el-menu-item>
        <el-menu-item index="7">维权服务</el-menu-item>
        <el-menu-item index="4">新闻中心</el-menu-item>
        <el-menu-item index="5">政策法规</el-menu-item>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import NewsAPI from '@/api/v1/news'
import {MenuItems} from "@/../config.js"
export default {
  name: "",
  data() {
    return {
      collapse: true,
      bannerList: [],
      drawer: false,
      direction: 'rtl',
      navScrollToTop: false,
    };
  },
  computed: {
    ...mapState(['navbar']),
    ...mapState({
      activeIndex: state => { return state.navbar.activeIndex },
    }),
    menus(){
         return MenuItems
    },
    navImage() {
      return this.activeIndex === '7' ? 'mobile_center_pic' : 'mobile_headerbanner'
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      NewsAPI.getImageBanner().then((res) => {
        this.bannerList = res.list
      })
    },
    // touch event 
    handleClose(done) {
      done();
    },
    handleSelect(key) {
      this.drawer = false
      if(key === "7") {
         this.$router.push({ name: "service" });
      } else if (key === '1') {
        this.$router.push({ name: 'home' })
        this.$store.commit('navbar/SET_POSITION_TOP')
      }
      else if (key === '2') {
        if (window.screen.width < 600) {
          this.drawer = true
        }
        return
      } else if (key === "6") {
        this.$router.push({ name: "business" });
      }
      else {
        let path = {'3': 1, '4': 3, '5': 2, '6': 6}
        let index = path[key]
        this.$router.push({ name: 'newsList', params: { type: index }})
      }
    },
    clickBanner(item) {
      let url
      if (item.external_link) {
        url = item.external_link
      } else {
        url = this.$router.resolve({name: 'articleDetail', params: { from: 0, id: item.id }}).href;
      }
      window.open(url, '_blank');
    },
    handleCommand(command) {
      if(command.callback) {
         command.callback()
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.menu-button {
  background rgba(0, 0, 0, 0)
  border none
  color white
}
@media only screen and (min-width: 600px) {

}



// mobile




@media only screen and (max-width: 600px) {
 /deep/.el-icon-menu {
  font-size 30px
}   
.mobile-navbar {
  height 100%
  display flex
  flex-direction column
}

.fixed {
  position fixed
  width 100%
  z-index 99
  background-color #2c53b0
  transition background-color 0.3s linear

  &.scrolled {
    background-color #2c53b0
  }
}

.fixed-bottom {
  padding-top 94px
}

.fixed-content {
  height 94px
  font-size 22px

  display flex
  justify-content space-between
  align-items center
  flex-shrink 0

  .header-title {
    font-size 20px
    color #0F64A9
    margin-left 15px 
  }
}

.solid-banner {
  height 216px
  position relative
}

.solid-image {
  width 100%
  height 216px
}

.menu-container {
  width 100%
  display none
}

.el-menu--collapse>.el-menu-item span, .el-menu--collapse>.el-submenu>.el-submenu__title span {
  visibility visible
  display inline
}

.el-menu {
  border-right none
}
}

</style>