var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _c("div", { staticClass: "fixed" }, [
      _c("div", { staticClass: "fixed-content" }, [
        _c(
          "div",
          { staticClass: "header-title" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "home" } } },
              [
                _c("el-image", {
                  staticClass: "logo-image",
                  attrs: { src: require("@/assets/hnlogo.svg") }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "menu-container" },
          [
            _c(
              "el-menu",
              {
                staticClass: "menu",
                attrs: {
                  "default-active": _vm.activeIndex,
                  mode: "horizontal"
                },
                on: { select: _vm.handleSelect }
              },
              [
                _c("el-menu-item", { attrs: { index: "1" } }, [_vm._v("首页")]),
                _c(
                  "el-menu-item",
                  { attrs: { index: "2" } },
                  [
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "dropdown-class",
                        attrs: { placement: "bottom" },
                        on: { command: _vm.handleCommand }
                      },
                      [
                        _c("span", { staticClass: "user" }, [_vm._v("星服务")]),
                        _c(
                          "el-dropdown-menu",
                          {
                            staticClass: "dropdown-class",
                            attrs: { slot: "dropdown" },
                            slot: "dropdown"
                          },
                          _vm._l(_vm.menus, function(menu) {
                            return _c(
                              "el-dropdown-item",
                              {
                                key: menu.caption,
                                attrs: { command: menu.command }
                              },
                              [_vm._v(_vm._s(menu.caption))]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-menu-item",
                  { attrs: { index: "6" } },
                  [
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "dropdown-class",
                        attrs: { placement: "bottom" },
                        on: { command: _vm.handleCommand }
                      },
                      [
                        _c("span", { staticClass: "user" }, [
                          _vm._v("业务说明")
                        ]),
                        _c(
                          "el-dropdown-menu",
                          {
                            staticClass: "dropdown-class",
                            attrs: { slot: "dropdown" },
                            slot: "dropdown"
                          },
                          _vm._l(_vm.businessMenus, function(menu) {
                            return _c(
                              "el-dropdown-item",
                              {
                                key: menu.caption,
                                attrs: { command: menu.command }
                              },
                              [_vm._v(_vm._s(menu.caption))]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-menu-item",
                  { attrs: { index: "7" } },
                  [
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "dropdown-class",
                        attrs: { placement: "bottom" },
                        on: { command: _vm.handleCommand }
                      },
                      [
                        _c("span", { staticClass: "user" }, [
                          _vm._v("维权服务")
                        ]),
                        _c(
                          "el-dropdown-menu",
                          {
                            staticClass: "dropdown-class",
                            attrs: { slot: "dropdown" },
                            slot: "dropdown"
                          },
                          _vm._l(_vm.rightMenus, function(menu) {
                            return _c(
                              "el-dropdown-item",
                              {
                                key: menu.caption,
                                attrs: { command: menu.command }
                              },
                              [_vm._v(_vm._s(menu.caption))]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("el-menu-item", { attrs: { index: "4" } }, [
                  _vm._v("新闻中心")
                ]),
                _c("el-menu-item", { attrs: { index: "5" } }, [
                  _vm._v("政策法规")
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "fixed-bottom" }, [
      _c(
        "div",
        { staticClass: "solid-banner" },
        [
          _c("el-image", {
            staticClass: "solid-image",
            attrs: {
              fit: "cover",
              src: require("@/assets/" + _vm.navImage + ".png")
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }