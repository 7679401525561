<template>
  <div class="navbar">
    <div class="fixed">
      <div class="fixed-content">
        <div class="header-title">
          <router-link :to="{ name: 'home' }">
            <el-image
              class="logo-image"
              :src="require('@/assets/hnlogo.svg')"
            ></el-image>
          </router-link>
        </div>
        <div class="menu-container">
          <el-menu
            class="menu"
            :default-active="activeIndex"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1">首页</el-menu-item>
            <el-menu-item index="2">
              <el-dropdown
                class="dropdown-class"
                @command="handleCommand"
                placement="bottom"
              >
                <span class="user">星服务</span>
                <el-dropdown-menu class="dropdown-class" slot="dropdown">
                  <el-dropdown-item
                    v-for="menu in menus"
                    :key="menu.caption"
                    :command="menu.command"
                    >{{ menu.caption }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-menu-item>
            <el-menu-item index="6">
              <el-dropdown
                class="dropdown-class"
                @command="handleCommand"
                placement="bottom"
              >
                <span class="user">业务说明</span>
                <el-dropdown-menu class="dropdown-class" slot="dropdown">
                  <el-dropdown-item
                    v-for="menu in businessMenus"
                    :key="menu.caption"
                    :command="menu.command"
                    >{{ menu.caption }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-menu-item>
            <el-menu-item index="7">
              <el-dropdown
                class="dropdown-class"
                @command="handleCommand"
                placement="bottom"
              >
                <span class="user">维权服务</span>
                <el-dropdown-menu class="dropdown-class" slot="dropdown">
                  <el-dropdown-item
                    v-for="menu in rightMenus"
                    :key="menu.caption"
                    :command="menu.command"
                    >{{ menu.caption }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-menu-item>
            <el-menu-item index="4">新闻中心</el-menu-item>
            <el-menu-item index="5">政策法规</el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>
    <div class="fixed-bottom">
      <div class="solid-banner">
        <el-image
          class="solid-image"
          fit="cover"
          :src="require('@/assets/' + navImage + '.png')"
        ></el-image>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NewsAPI from "@/api/v1/news";
import { MenuItems, RightMenuItems, BusinessMenuItems } from "@/../config.js";
export default {
  name: "",
  data() {
    return {
      collapse: true,
      bannerList: [],
      drawer: false,
      direction: "rtl",
      navScrollToTop: false,
    };
  },
  computed: {
    ...mapState({
      activeIndex: (state) => {
        return state.navbar.activeIndex;
      },
    }),
    menus() {
      return MenuItems;
    },
    rightMenus() {
      return RightMenuItems;
    },
    businessMenus() {
      return BusinessMenuItems;
    },
    navImage() {
      switch (this.activeIndex) {
        case "7":
          return "service";
        case "6":
          return "business";
        default:
          return "headerbanner";
      }
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      NewsAPI.getImageBanner().then((res) => {
        this.bannerList = res.list;
      });
    },
    // touch event
    handleClose(done) {
      done();
    },
    handleSelect(key) {
      this.drawer = false;
      if (key === "7") {
        this.$router.push({ name: "service" });
      } else if (key === "1") {
        this.$router.push({ name: "home" });
        this.$store.commit("navbar/SET_POSITION_TOP");
      } else if (key === "2") {
        if (window.screen.width < 600) {
          this.drawer = true;
        }
      } else if (key === "6") {
        this.$router.push({ name: "business" });
      } else {
        let path = { 3: 1, 4: 3, 5: 2, 6: 6 };
        let index = path[key];
        this.$router.push({ name: "newsList", params: { type: index } });
      }
    },
    clickBanner(item) {
      let url;
      if (item.external_link) {
        url = item.external_link;
      } else {
        url = this.$router.resolve({
          name: "articleDetail",
          params: { from: 0, id: item.id },
        }).href;
      }
      window.open(url, "_blank");
    },
    handleCommand(command) {
      if (command.callback) {
        command.callback();
      } else {
        this.$router.push({ name: command });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@media only screen and (min-width: 600px) {
  .navbar {
    height: 347px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .fixed {
    position: fixed;
    width: 100%;
    z-index: 99;
    background-color: #2c53b0;
    transition: background-color 0.3s linear;
    height: 131px;
  }

  .fixed-bottom {
    height: 472px;
    margin-top: 131px;
  }

  .fixed-content {
    height: 131px;
    width: 1200px;
    margin: auto;
    flex-shrink: 0;
    flex-grow: 0;

    .header-title {
      flex-shrink: 0;
      height: 50%;
    }

    .nav-drawer {
      display: none;
    }
  }

  .logo-image {
    height: 47px;
    margin-top: 19px;
  }

  .menu-container {
    height: 50%;
    flex-grow: 0;
    display: flex;

    .menu {
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      background: #ffffff00;
      align-items: center;
    }

    .el-menu-item {
      text-align: center;
      background: #ffffff00;
      color: white;
      font-size: 18px;
      height: 36px;
      line-height: 36px;
      border-radius: 6px;
      margin: 0px 5px;

      &:hover {
        background: rgba(255, 255, 255, 0.16);
        height: 36px;
        color: #FFFFFF;
        font-weight: bold;

        .el-dropdown {
          color: #FFFFFF;
          font-weight: bold;
        }
      }
    }

    .el-menu-item.is-active {
      background: #ffffff;
      font-weight: 800;
      color: #2C53B0;
      border-radius: 6px;
      border-bottom: none;

      .el-dropdown {
        color: #2C53B0;
      }
    }

    .el-menu.el-menu--horizontal {
      border-bottom: none;
    }
  }

  .el-menu-item * {
    vertical-align: baseline;
  }

  .el-dropdown {
    width: 100%;
    height: 100%;
    color: white;
  }

  .user {
    display: inline-block;
    width: 100%;
    height: 100%;
    font-size: 18px;
  }

  .solid-banner {
    height: 216px;
    position: relative;
  }

  .solid-image {
    width: 100%;
    height: 100%;
  }

  .el-dropdown-menu__item {
    padding: 13px 10px 13px 10px;
    background: #2c53b0;
    color: white;
    font-size: 14px;
    height: fit-content;
    width: 140px;
    text-align: center;
    line-height: 24px;

    &:hover {
      background: #5370BE;
      color: white !important;
    }
  }

  .el-dropdown-menu {
    border: none;
    transform: translateY(4px);
    border-radius: 6px;
    overflow: hidden;
    background: transparent;
    margin-top: -2px !important;
    padding-top: 10px !important;

    li:first-of-type {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    box-shadow: none !important;
  }

  .dropdown-class {
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }

  /deep/.popper__arrow {
    display: none;
    top: 0 !important;
    border: none;
  }

  .el-popper {
    margin-top: -1px;
  }
}
</style>
